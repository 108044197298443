import { matchPath } from "react-router-dom";

export const hideMobileNavbarRoutes = [
  "/login",
  "/products/add",
  "/product-loss/add",
  "/product-loss/:id/edit",
  "/vends/upload",
  "/settings/user-management",
  "/refill/success",
  "/settings/user-management/add",
  "/settings/user-management/:id/edit",
];
export const shouldHideNavbar = (pathname: string): boolean => {
  return hideMobileNavbarRoutes.some((route) => {
    return (
      matchPath(
        {
          path: route,
          end: true,
        },
        pathname,
      ) !== null
    );
  });
};
